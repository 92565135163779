<template>
  <section class="ant-layout site-layout">
    <header class="ant-layout-header site-header">
      <div class="flex-between i-back-normal-head">
        <div class="i-back-head-left">
          <span class="i-back-normal-head-text">邀请详情</span>
        </div>
        <div class="i-back-normal-head-right-text"></div>
      </div>
    </header>
    <main class="ant-layout-content main-content">
      <div class="activity-detail flex-column">
        <div class="i-card flex-between" style="margin-top: 16px">
          <div class="i-card card-whit flex-center i-segment activity-detail-segment" style="width: 50%">
            <div class="segment-item">
              <div>
                <span class="value" style="color: rgb(248, 77, 77)">{{activity.exchangeDayCount}}</span>
                <span class="segment-unit"> 天</span>
              </div>
              <div class="segment-text">已兑换时长</div>
            </div>
            <div class="segment-item"></div>
          </div>
        </div>
        <div class="i-special-card" style="margin-top: 16px; flex: 1 1 0%; overflow-y: auto">
          <div class="ant-table ant-table-small activity-table-header">
            <table style="table-layout: auto;width: 100%;height: 48px;background: rgb(255, 255, 255);">
              <thead class="ant-table-thead">
                <tr class="ant-table-row ant-table-row-level-0">
                  <th class="ant-table-cell" style="width: 33%">用户名</th>
                  <th class="ant-table-cell" style="width: 33%">邀请时间</th>
                  <th class="ant-table-cell" style="width: 34%">状态</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invite in inviteList" :key="invite.id" class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell"><img v-if="!!invite.avatar" :src="invite.avatar" style="width:30px;height:30px;border-radius: 50%;margin-right:5px;"/>{{ invite.nickname || invite.username }}</td>
                  <td class="ant-table-cell">{{ invite.createtime }}</td>
                  <td class="ant-table-cell">{{ invite.statusText }}</td>
                </tr>
              </tbody>
            </table>
            <Page
              v-if="pageTotal > 1"
              :total="total"
              :current="page"
              :page-size="size"
              @on-change="handlePageChange"
              @on-page-size-change="handlePageSize"
              show-total
              show-elevator
              show-sizer
              class="page-custom-ui"/>
          </div>
          <div v-if="total == 0" class="ant-empty flex-column-center" style="height: calc(100% - 48px)">
            <div class="ant-empty-image" style="height: 174px">
              <img alt="暂无数据" src="../../assets/static/empty_data.7dd60602.svg"/>
            </div>
            <div class="ant-empty-description">暂无数据</div>
          </div>
        </div>
      </div>
    </main>

    <div v-show="showExchangeCodeModal" class="ant-modal-root">
      <div class="ant-modal-mask"></div>
      <div class="ant-modal-wrap">
        <div class="ant-modal ant-modal-confirm ant-modal-confirm-confirm" style="width: 416px; transform-origin: 499.2px 12px 0px">
          <div class="ant-modal-content">
            <div class="ant-modal-body">
              <div class="ant-modal-confirm-body-wrapper">
                <div v-if="activity.dayCount == 0" class="ant-modal-confirm-body">
                  <span class="ant-modal-confirm-title">暂无时长</span>
                  <div class="ant-modal-confirm-content">
                    您暂时没有可兑换的时长，快去邀请更多好友。
                  </div>
                </div>
                <div v-else class="ant-modal-confirm-body">
                  <span class="ant-modal-confirm-title"></span>
                  <div class="ant-modal-confirm-content">
                    您的可兑换时长为{{activity.dayCount}}天。
                  </div>
                </div>
                <div class="ant-modal-confirm-btns">
                  <button @click="showExchangeCodeModal = false" type="button" class="ant-btn">
                    <span>好 的</span>
                  </button>
                  <button
                    v-if="activity.dayCount > 0" 
                    @click="exchangeDays"
                    type="button"
                    class="ant-btn ant-btn-primary">
                    <span>确 定</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  name: "activityDetail",
  data() {
    return {
      activity: {},
      total: 0,
      page: 1,
      size: 20,
      pageTotal: 0,
      inviteList: [],
      showExchangeCodeModal: false
    };
  },
  created() {
    this.getActivityDetailData();
    this.getInviteList();
  },
  methods: {
    getInviteList() {
      this.$http
        .post("/api/user/getInviteList", { pn: this.page, ps: this.size })
        .then((data) => {
          data.list.forEach((invite) => {
            invite.createtime = formatDate(invite.createtime,"YYYY-MM-DD HH:mm");
          });
          this.inviteList = data.list;
          this.page = data.pageNum;
          this.size = data.pageSize;
          this.total = data.total;
          this.pageTotal = data.pages;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.getInviteList();
    },
    handlePageSize(size) {
      this.size = size;
      this.page = 1;
      this.getInviteList();
    },
    getActivityDetailData() {
      this.$http.post("/api/user/getActivityDetailData").then((data) => {
        this.activity = data;
      });
    },
    exchangeDays(){
      this.$http.post("/api/user/exchangeActivityMemberDays").then(() => {
        this.$Message.success("兑换成功");
        this.getActivityDetailData();
        this.showExchangeCodeModal = false;
      });
    }
  },
};
</script>
<style scoped>
.activityDetail {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
table tbody > tr > td {
  padding: 12px 8px;
  text-align: center;
}
table tbody > tr > td:first-child {
  padding-left: 24px;
  text-align: left;
}
table tbody > tr > td:last-child {
  padding-right: 24px;
  text-align: right;
}
</style>